<template>
	<v-card flat class="wr_grey_1">
		<v-row no-gutters>
			<v-col cols="1" align="center">
				<v-btn
					small
					@click="createSource()"
					fab
					dark
					color="success"
					class="mt-4"
				>
					<v-icon dark large>$vuetify.icons.values.plus</v-icon>
				</v-btn>
			</v-col>
			<v-col cols="3">
				<div class="text-h6 font-weight-bold">
					{{ $t("app.gather_info.source_info") }}
				</div>
				<div>
					{{ $t("app.gather_info.source_info.intr_1") }}
				</div>
				<div class="wr_blue_1--text mt-4">
					{{ $t("app.gather_info.source_info.intr_2") }}
				</div>
			</v-col>
			<v-col cols="6">
				<div class="text-h6 font-weight-bold">
					{{ $t("app.gather_info.source_info.desc") }}
				</div>
				<div>
					{{ $t("app.gather_info.source_info.intr_3") }}
				</div>
			</v-col>
			<v-col cols="2" align="center">
				<ImportMindmapReferencesDialog
					@add-reference-source="createSource"
				></ImportMindmapReferencesDialog>
			</v-col>
		</v-row>

		<template v-for="(source, index) in source_list">
			<v-card flat class="mt-6 wr_grey_1" :key="source.id">
				<v-row no-gutters>
					<v-col sm="1" class="display-2 wr_blue_1--text" align="center">{{
						index + 1
					}}</v-col>
					<v-col sm="11">
						<v-card>
							<v-card-text>
								<v-row no-gutters>
									<v-col sm="9">
										<v-row no-gutters>
											<v-col sm="11">
												<div>
													<u
														v-if="source.title"
														class="font-weight-bold black--text"
														>{{ source.title }}</u
													>
													<i v-else>{{ $t("app.click_edit__to_add_title") }}</i>
													<MetaDialog :sourceId="source.id" />
												</div>
												<div>
													<read-more
														v-if="source.description"
														:more-str="$t('app.read_more')"
														:text="source.description"
														:less-str="$t('app.read_less')"
														:max-chars="250"
														class="black--text"
													></read-more>
													<i v-else>{{
														$t("app.click_edit__to_add_description")
													}}</i>
												</div>
											</v-col>
											<v-col sm="1" align="end">
												<v-btn
													icon
													color="error"
													@click="deleteSource(source.id)"
												>
													<v-icon>$vuetify.icons.values.delete</v-icon>
												</v-btn>
											</v-col>
										</v-row>
									</v-col>

									<v-col sm="1" align="center">
										<v-divider class="wr-dash-divider" vertical></v-divider>
									</v-col>

									<v-col sm="2" align="center">
										<SourceAddons
											:sourceId="source.id"
											:showAddons="true"
											:showAdd="true"
											@addLink="addLink"
										/>
									</v-col>
								</v-row>
							</v-card-text>

							<!-- Show notes -->
							<v-divider></v-divider>
							<SourceNotes :sourceId="source.id"></SourceNotes>
						</v-card>
					</v-col>
				</v-row>
			</v-card>
		</template>

		<!-- Confirmation box for delete operation -->
		<TheConfirmation
			v-model="delDialog"
			:dialog="delDialog"
			@cancel="delDialog = false"
			@confirm="sourceDel()"
		></TheConfirmation>
	</v-card>
</template>

<script>
import { sourceMixin } from "@/mixins/sourceMixin";
import SourceAddons from "@/components/research/modules/gatherSources/source/Addons.vue";
import TheConfirmation from "@/components/layout/TheConfirmation";
import MetaDialog from "@/components/research/modules/gatherSources/source/MetaDialog.vue";
import SourceNotes from "@/components/research/modules/gatherSources/source/Notes.vue";
import ImportMindmapReferencesDialog from "@/components/research/modules/defineQuestion/stages/gatherInformation/ImportMindmapReferencesDialog.vue";

export default {
	name: "GatherInformation",

	data() {
		return {
			delDialog: false,
			delSourceId: null,
		};
	},

	components: {
		SourceAddons,
		TheConfirmation,
		MetaDialog,
		SourceNotes,
		ImportMindmapReferencesDialog,
	},

	mixins: [sourceMixin],

	methods: {
		addLink(payload) {
			payload.source.url = payload.url;
			this.updateSource(payload.source);
		},

		deleteSource(sourceId) {
			this.delSourceId = sourceId;
			this.delDialog = true;
		},

		async updateSource(source) {
			try {
				this.$loader.start();
				/**
				 * del attachment key as put api expect attachment
				 * to always be a file
				 */
				let newSource = Object.assign({}, source);
				delete newSource["attachment"];

				await this.$store.dispatch("source/update", newSource);
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
			}
		},

		async sourceDel() {
			try {
				this.$loader.start();
				await this.$store.dispatch("source/del", {
					id: this.delSourceId,
				});
				this.$store.dispatch("source/list");
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
				this.delDialog = false;
			}
		},
	},
};
</script>
