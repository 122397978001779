<template>
	<v-card flat class="wr_grey_1">
		<!-- Intro to understand the stage -->
		<StageIntroDialog />

		<!-- Gather Source -->
		<GatherInformation></GatherInformation>

		<!-- Show finish source documentation  -->
		<StageFinishDialog
			v-if="dialog"
			v-model="dialog"
			:dialog="dialog"
			current_step="app.gather_info.title"
			@close="dialog = false"
		></StageFinishDialog>
	</v-card>
</template>

<script>
import StageIntroDialog from "@/components/research/modules/_common/stages/IntroDialog";
import GatherInformation from "@/components/research/modules/defineQuestion/stages/gatherInformation/Index.vue";
import StageFinishDialog from "@/components/research/modules/_common/stages/FinishDialog";
import { mapState } from "vuex";
import { findFromArray } from "@/utils/helpers";
import { validateCreateSource } from "@/utils/helpers/source";

export default {
	name: "GatherInformationPage",

	data() {
		return {
			dialog: false,
		};
	},

	computed: {
		...mapState({
			source_list: (state) => state.source.list,
		}),
	},

	components: {
		StageIntroDialog,
		GatherInformation,
		StageFinishDialog,
	},

	created() {
		this.$eventBus.$on("finish-gather-info", (payload) => {
			try {
				validateCreateSource();
				this.dialog = true;
			} catch (error) {
				this.$announce.error(error);
			}
		});
	},

	beforeDestroy() {
		/**
		 * Vue does not destroy listeners until we don't load the page and that's why
		 * whenever any event emit the previously present listeners can also
		 * listen the emitted event and there could be a problem of duplicate
		 * triplicate listeners. So destroy those manually.
		 */
		this.$eventBus.$off("finish-gather-info");
	},
};
</script>
